import React from "react"
import SEO from "../components/seo"

import { CardLeft, CardRight, Line } from "../components/elements"
import Layout from "../components/layout"
import {
  HeaderPage,
  IntroSection,
  Services,
  ProductDetails,
  ReasonSection,
  ServiceDetail,
  HeaderMain,
} from "../components/structure"

import headerIcon from "../images/brand/headerIcon.svg"

import banner from "../images/brand/banner.svg"
import brochure from "../images/brand/brochure.svg"
import flyer from "../images/brand/flyer.svg"
import infographic from "../images/brand/infographic.svg"
import logo from "../images/brand/logo.svg"
import mediabranding from "../images/brand/mediabranding.svg"
import poster from "../images/brand/poster.svg"

export default function BrandIdentityDevelopment() {
  return (
    <Layout>
      <SEO title="Brand identity Development" />
      {/* <HeaderMain
        image={headerIcon}
        catchy="Brand Identity Development"
        title="Your business has a persona. Let it shine."
        tagline="Foster an image that your customers can relate to. Give your business the individuality to find its ideal audience and stand out from the competition."
      /> */}
      <HeaderPage
        src={headerIcon}
        color={props => props.theme.procolors.brandDark}
        bgColor={props => props.theme.procolors.brandLight}
        title="Your business has a persona. Let it shine."
        catchy="Brand Identity Development"
        tagline="Foster an image that your customers can relate to. Give your business the individuality to find its ideal audience and stand out from the competition."
      />
      <Line />
      <ServiceDetail>
        <IntroSection
          title="Brand identity"
          details="Your brand identity is the personality of your business. It lets your customers know what you’re all about and build faith in you, so it’s worth the investment. Our team members are experts when it comes to brand identity development and can implement a number of methods to create a distinct image for your business."
          color={props => props.theme.procolors.brandDark}
        />
        <ProductDetails
          to="/contact/"
          title="Our brand identity development services include:"
          color={props => props.theme.procolors.brandDark}
        >
          <CardLeft
            alt="Logo design"
            title="Logo design"
            detail="Let us design the perfect logo that represents your business and stands out from the competition."
            src={logo}
          />
          <CardRight
            alt="Brochure design"
            title="Brochure design"
            detail="With our brochure design services, you can trust that you’ll have a creative and efficient brochure template to use for B2B and B2C operations."
            src={brochure}
          />
          <CardLeft
            alt="Flyer design"
            title="Flyer design"
            detail="Our flyer design service will create eye-catching flyers that capture the essence of your business and help increase your brand’s visibility."
            src={flyer}
          />
          <CardRight
            alt="Social media branding"
            title="Social media branding"
            detail="Our social media branding will help your customers understand who you are, what you do, and why they should choose you."
            src={mediabranding}
          />
          <CardLeft
            alt="Poster design"
            title="Poster design"
            detail="Use our poster design service to help increase your brand’s presence and appeal to new customers."
            src={poster}
          />
          <CardRight
            alt="Exhibition banner design"
            title="Exhibition banner design"
            detail="Let us create your ideal exhibition banner that perfectly sums up your brand and adds a touch of finesse to your business events."
            src={banner}
          />
          <CardLeft
            alt="Infographic design"
            title="Infographic design"
            detail="Stand out from the online crowd and appeal to an ever-adapting audience with our striking infographics."
            src={infographic}
          />
        </ProductDetails>
        <ReasonSection
          borderColor={props => props.theme.procolors.brandLight}
          title="Why Do You Need Brand Identity Development?"
          details="Quality branding will help to covert leads and build loyalty in your existing customers. It shows your audience how you’re different from the crowd and helps them to remember you out of a sea of competitors. Strong branding will also help your customers to understand your mission."
          details1="While your products and services matter, effective branding can be the difference between whether or not you make the sale. So a development strategy will help your business to soar over the long-term."
        />
      </ServiceDetail>
      <Line />
      <Services />
    </Layout>
  )
}
